import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import MessagesList from "./MessagesList";

const Chat = ({ singleRemont }) => {
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <MessagesList singleRemont={singleRemont} />
    </Box>
  );
};

export default Chat;

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    maxHeight: "87vh",
    border: "1px solid #E2E3ED",
    backgroundColor: "#fff",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.breakpoints.down(768)]: {
      maxHeight: "100vh",
    },
  },
}));
