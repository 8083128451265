import { addStageMediaReportAPI, getStageMediaReport } from "../services/api";

export const getStageMediaReportData = async (remontId) => {
  try {
    const res = await getStageMediaReport(remontId);
    return res?.data;
  } catch (e) {}
};
export const addStageMediaReport = async (remontId, stageId, files = []) => {
  if (!files?.length) return;
  const body = new FormData();
  body.append("stage_id", stageId);
  files.forEach((item) => body.append("photos", item));
  try {
    const res = await addStageMediaReportAPI(remontId, body);
    return res?.data;
  } catch (e) {}
};
