import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

export const Clickable = ({
  children,
  link,
  href,
  blockMode,
  routerLink,
  altMode,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div onClick={() => onClick && onClick()}>
      {link ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={href}
          className={clsx([
            classes.clickable,
            { [classes.clickable__block]: blockMode },
            { [classes.clickable__alt]: altMode },
          ])}
        >
          {children}
        </a>
      ) : routerLink ? (
        <Link
          target="_blank"
          to={href}
          className={clsx([
            classes.clickable,
            { [classes.clickable__block]: blockMode },
            { [classes.clickable__alt]: altMode },
          ])}
        >
          {children}
        </Link>
      ) : (
        <span
          className={clsx([
            classes.clickable,
            { [classes.clickable__block]: blockMode },
            { [classes.clickable__alt]: altMode },
          ])}
        >
          {children}
        </span>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  clickable: {
    color: "#3d74dc",
    textDecoration: "none",
    transition: "all .3s",
    cursor: "pointer",
    "&:hover": {
      color: "#83aaef",
      textDecoration: "underline",
    },
  },
  clickable__block: {
    display: "block",
  },
  clickable__alt: {
    "&:hover": {
      color: "#3d74dc",
      textDecoration: "underline",
    },
  },
});
