import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CheckIconWrapper,
  CloseIconWrapper,
} from "../../../components/common/blocks";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow,
} from "../../../components/common/CustomTable/CustomTable";
import { getCallRequests, getStages } from "../services/api";

const Stages = () => {
  const classes = useStyles();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [isFetching, setIsFetching] = useState(false);
  const [isCallsFetching, setIsCallsFetching] = useState(false);
  const [stages, setStages] = useState([]);
  const [calls, setCalls] = useState([]);

  useEffect(() => {
    setIsFetching(true);
    getStages(remont_id)
      .then((res) => {
        setIsFetching(false);
        setStages(res);
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, [remont_id]);

  useEffect(() => {
    setIsCallsFetching(true);
    getCallRequests(remont_id)
      .then((res) => {
        setIsCallsFetching(false);
        setCalls(res.check_group_calls);
      })
      .catch(() => {
        setIsCallsFetching(false);
      });
  }, [remont_id]);

  return (
    <div>
      <CustomTable
        headItems={headerTitles}
        isEmpty={!stages?.length}
        isFetching={isFetching}
      >
        {stages?.map((el) => {
          return (
            <CustomTableRow key={el?.remont_stage_id}>
              <CustomTableCell align={"center"}>
                {el.remont_stage_id}
              </CustomTableCell>
              <CustomTableCell>{el.stage_name}</CustomTableCell>
              <CustomTableCell
                style={{ backgroundColor: getBgColor(el.stage_status_id) }}
              >
                {el.status_name}
              </CustomTableCell>
              <CustomTableCell>{el.date_create}</CustomTableCell>
              <CustomTableCell>{el.fio}</CustomTableCell>
              <CustomTableCell>{el.comments}</CustomTableCell>
            </CustomTableRow>
          );
        })}
      </CustomTable>

      <div className={classes.callsTable}>
        <h3>Вызовы на проверку завершенности работ</h3>
        <CustomTable
          headItems={workRequestsHeads}
          isEmpty={!stages?.length}
          isFetching={isCallsFetching}
        >
          {calls?.map((el) => {
            return (
              <CustomTableRow key={el?.check_group_call_id}>
                <CustomTableCell align={"center"}>
                  {el.check_group_call_id}
                </CustomTableCell>
                <CustomTableCell>
                  {el.work_set_check_group_name}
                </CustomTableCell>
                <CustomTableCell>{el.call_type_name}</CustomTableCell>
                <CustomTableCell align={"center"}>
                  {el.call_date}
                </CustomTableCell>
                <CustomTableCell>{el.fio}</CustomTableCell>
                <CustomTableCell align={"center"}>
                  {el?.is_design_checked ===
                  null ? null : el?.is_design_checked ? (
                    <CheckIconWrapper />
                  ) : (
                    <CloseIconWrapper />
                  )}
                </CustomTableCell>
                <CustomTableCell
                  style={{
                    backgroundColor: el.checked_status_colour,
                  }}
                >
                  {el?.checked_status || ""}
                </CustomTableCell>
                <CustomTableCell>{el?.comment || ""}</CustomTableCell>
              </CustomTableRow>
            );
          })}
        </CustomTable>
      </div>
    </div>
  );
};

export default Stages;

export const useStyles = makeStyles((theme) => ({
  callsTable: {
    marginTop: 25,
  },
}));

const getBgColor = (status_id) => {
  switch (status_id) {
    case 1:
      return "#fcf403";
    case 2:
      return "#fc3903";
    case 3:
      return "#fca903";
    case 4:
      return "#3ef77c";
    default:
      return "";
  }
};

const headerTitles = [
  { align: "center", label: "ID" },
  { align: "left", label: "Этап" },
  { align: "left", label: "Статус этапа" },
  { align: "left", label: "Дата Создания" },
  { align: "left", label: "Примечание" },
  { align: "left", label: "Комментарий" },
];
const workRequestsHeads = [
  { align: "center", label: "ID" },
  { align: "center", label: "Группа работ" },
  { align: "left", label: "Тип вызова" },
  { align: "center", label: "Дата вызова" },
  { align: "left", label: "ФИО" },
  { align: "center", label: "Принят МП" },
  { align: "center", label: "Статус" },
  { align: "left", label: "Комментарий" },
];
